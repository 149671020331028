// src/pages/UpscalerPage.js

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import Cookies from 'js-cookie';
import CustomButton from '../components/shared/CustomButton';
import Gallery from '../components/shared/Gallery';
import SettingsPanel from '../components/shared/SettingsPanel';
import ImageDisplay from '../components/shared/ImageDisplay';
import DownloadSection from '../components/shared/DownloadSection';
import SharedLayout from '../components/shared/SharedLayout';
import '../styles/UpscalerPage.css';
import usePreventRightClick from './usePreventRightClick';
import Hotjar from '@hotjar/browser';
import { useActionCard } from './ActionCardContext';
import ImageModal from './ImageModal';

// Define model options
const modelOptions = {
  Auto: ' <lora:Natural_Daylight_portrait:1>',
  Studio: '<lora:studio-portrait:1>',
  Night: '<lora:night-portrait:1>',
  Shadow: '<lora:shadow-play-portrait-final:1>',
};

// Define default denoising_strength for endpoints
const endpointDefaultDenoising = {
  upscale: 0.07,
  'upscaler-highres': 0.07,
  'upscaler-portrait': 0.07,
};

// Conversion functions between denoising_strength and creativity
const denoisingToCreativity = (denoising_strength) =>
  Math.round((denoising_strength / 1) * 100); // denoising_strength ranges from 0.07 to 1

const creativityToDenoising = (creativity) =>
  parseFloat(((creativity / 100) * 1).toFixed(2)); // creativity from 0 to 100 maps to denoising_strength 0 to1

// Updated Conversion Functions for Creativity/Denoising
const visualToBackendValues = (visualValue, endpoint = 'upscale') => {
  // Clamp visualValue between 0 and 100
  const clampedVisual = Math.min(Math.max(visualValue, 0), 100);
  console.log(`visualToBackendValues: Slider value = ${visualValue}, Clamped = ${clampedVisual}`);

  if (clampedVisual === 0) {
    // Return default endpoint values when slider is at 0
    return {
      denoisingStrength: endpointDefaultDenoising[endpoint] || 0.07, // Use endpoint default
      secondaryValue: 0.07,     // Default secondary value
    };
  }

  // Map visualValue (1-100) to denoising_strength (0.07-1.00) and secondary_value (0.07-0.20)
  const denoisingStrength = parseFloat(((clampedVisual / 100) * (1.00 - 0.07) + 0.07).toFixed(2));
  const secondaryValue = parseFloat(((clampedVisual / 100) * (0.20 - 0.07) + 0.07).toFixed(2));

  return {
    denoisingStrength,
    secondaryValue,
  };
};


const backendToVisualValue = (denoisingStrength, secondaryValue) => {
  if (denoisingStrength === 0 && secondaryValue === 0) {
    console.log(`backendToVisualValue: denoisingStrength = ${denoisingStrength}, secondaryValue = ${secondaryValue} => Slider value = 0`);
    return 0; // Disabled
  }

  // denoisingStrength = 0.07-1.00
  // visualValue = ((denoising_strength - 0.07)/0.93)*100
  const visualValue = Math.round(((denoisingStrength - 0.07) / (1.00 - 0.07)) * 100);
  console.log(`backendToVisualValue: denoisingStrength = ${denoisingStrength}, secondaryValue = ${secondaryValue} => Slider value = ${visualValue}`);

  return Math.min(Math.max(visualValue, 1), 100);
};

// Mapping function for Composition to Resemblance
const mapCompositionToResemblance = (composition) => {
  if (composition === 0) {
    return 1; // Default resemblance value when composition is 0
  }

  const minComposition = 0;
  const maxComposition = 10;
  const minResemblance = 1;
  const maxResemblance = 1.5;

  const clampedComposition = Math.min(Math.max(composition, minComposition), maxComposition);

  const resemblance =
    ((clampedComposition - minComposition) / (maxComposition - minComposition)) *
      (maxResemblance - minResemblance) +
    minResemblance;

  console.log(`mapCompositionToResemblance: composition = ${composition} => resemblance = ${resemblance}`);

  return resemblance;
};


// Updated Mapping Functions for Film Grain Strength
const mapGrainStrength = (sliderValue) => {
  // Validate slider value first
  if (typeof sliderValue !== 'number') {
    console.error(`Invalid slider value type: ${typeof sliderValue}`);
    return 0;
  }

  // Ensure the slider value is an integer
  const normalizedValue = Math.round(sliderValue);

  // Handle disabled state (0)
  if (normalizedValue === 0) {
    console.log(`Film grain disabled: slider = 0, backend = 0`);
    return 0;
  }

  // Validate slider range (1-4)
  if (normalizedValue < 1 || normalizedValue > 4) {
    console.error(`Slider value out of range: ${normalizedValue}`);
    return 0;
  }

  // Map to backend values (4-7)
  const backendValue = normalizedValue + 3;
  console.log(`Film grain mapping: slider = ${normalizedValue}, backend = ${backendValue}`);
  return backendValue;
};

// Map backend 0 or 4-7 to slider 0-4
const mapGrainStrengthBackendToSlider = (backendValue) => {
  if (backendValue === 0) {
    console.log(`mapGrainStrengthBackendToSlider: Backend value is ${backendValue}, returning 0.`);
    return 0; // Disabled
  }
  if (backendValue >= 4 && backendValue <= 7) {
    const sliderValue = backendValue - 3; // Maps 4-7 to 1-4
    console.log(`mapGrainStrengthBackendToSlider: Backend value = ${backendValue}, Slider value = ${sliderValue}`);
    return sliderValue;
  }
  console.warn(`mapGrainStrengthBackendToSlider: Unexpected backend value ${backendValue}, defaulting to 0.`);
  return 0; // Default to disabled for unexpected values
};

// Constants
const BASE_RESOLUTION = 1024 * 1024; // 1 MP
const MIN_TOKENS = 1; // Minimum tokens required

// Updated Default settings for Creativity and Film Grain Strength
const DEFAULTS = {
  creativity: {
    visualValue: 0,            // 0-100 range
    denoisingStrength: 0.07,      // 0.07-1.00 or 0 (disabled)
    secondaryValue: 0.07,         // 0.07-0.20 or 0 (disabled)
  },
  resemblance: 0,               // Default for Composition
  hdrStrength: 0,               // Default for HDR is OFF (0)
  filmGrainVisual: 1,           // Slider value (0-4)
  filmGrainStrength: 4,         // Backend value (0 or 4-7)
};

const UpscalerPage = () => {
  usePreventRightClick();
  const navigate = useNavigate();
  const { selectedCard } = useActionCard();

  // State Variables
  const [scaleFactor, setScaleFactor] = useState(2);
  const [creativity, setCreativity] = useState(DEFAULTS.creativity);
  const [creativityEnabled, setCreativityEnabled] = useState(false);
  const [resemblance, setResemblance] = useState(DEFAULTS.resemblance);
  const [resemblanceEnabled, setResemblanceEnabled] = useState(false);
  const [hdrStrength, setHdrStrength] = useState(DEFAULTS.hdrStrength);
  const [hdrStrengthEnabled, setHdrStrengthEnabled] = useState(false); // HDR is OFF by default

  // New State Variables for Film Grain Strength
  const [filmGrainVisual, setFilmGrainVisual] = useState(DEFAULTS.filmGrainVisual); // Slider value (0-4)
  const [filmGrainStrength, setFilmGrainStrength] = useState(DEFAULTS.filmGrainStrength); // Backend value (0 or 4-7)
  const [filmGrainStrengthEnabled, setFilmGrainStrengthEnabled] = useState(true); // Disabled by default

  const [originalImageUrl, setOriginalImageUrl] = useState('');
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [upscaledImageUrl, setUpscaledImageUrl] = useState('');
  const [isUpscaled, setIsUpscaled] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [isUpscaling, setIsUpscaling] = useState(false);
  const [selectedModel, setSelectedModel] = useState('Auto');
  const [tokensAvailable, setTokensAvailable] = useState(null);
  const [tokensRequired, setTokensRequired] = useState(MIN_TOKENS);
  const [zoomLevel, setZoomLevel] = useState(100);
  const [showInputVariants, setShowInputVariants] = useState(true);
  const [showDownloadSection, setShowDownloadSection] = useState(true);
  const [isDraggingEnabled, setIsDraggingEnabled] = useState(false);
  const [isDefault, setIsDefault] = useState(true);
  const [galleryImages, setGalleryImages] = useState([]);
  const [imageMetadata, setImageMetadata] = useState([]);
  const [selectedImageState, setSelectedImageState] = useState('');
  const [selectedMetadata, setSelectedMetadata] = useState({
    width: 0,
    height: 0,
    format: 'UNKNOWN',
    scaleFactor: 2, // Updated to match DEFAULTS.scaleFactor
    isProcessed: false,
    creativity: DEFAULTS.creativity.denoisingStrength,
    composition: DEFAULTS.resemblance,
    hdrStrength: DEFAULTS.hdrStrength,
    filmGrainStrength: DEFAULTS.filmGrainStrength, // Backend value
    optimizedFor: 'Auto',
  });
  const [nextPageToken, setNextPageToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeSession, setActiveSession] = useState(null);
  const [defaultCreativity, setDefaultCreativity] = useState(() => {
    const endpoint = selectedCard?.endpoint || 'upscale';
    return {
      denoisingStrength:
        endpointDefaultDenoising[endpoint] || endpointDefaultDenoising['upscale'],
      secondaryValue: 0.07, // Updated default secondary_value to 0.07
    };
  });

  // New State Variable for Manual Adjustment
  const [isManuallyAdjusting, setIsManuallyAdjusting] = useState(false);

  // Initialize Hotjar
  useEffect(() => {
    Hotjar.init(
      process.env.REACT_APP_HOTJAR_ID,
      process.env.REACT_APP_HOTJAR_SNIPPET_VERSION
    );
  }, []);

  // Function to deduct credits
  const deductCredits = async (creditsCost) => {
    try {
      const token = Cookies.get('token');
      if (!token) {
        throw new Error('User not authenticated');
      }

      const response = await fetch('https://www.ek0go8g.vudoo.ai/api/credits', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ action: 'deduct', creditsCost }),
      });

      if (!response.ok) {
        throw new Error('Failed to deduct credits');
      }

      const result = await response.json();
      return result.newCredits;
    } catch (error) {
      alert('Failed to deduct credits. Please try again.');
      throw error;
    }
  };

  // Function to fetch user profile
  const fetchUserProfile = useCallback(async () => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await fetch('https://www.ek0go8g.vudoo.ai/api/profile', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch user profile');

      const profileData = await response.json();
      setTokensAvailable(profileData.credits || 0);
    } catch (error) {
      navigate('/login');
    }
  }, [navigate]);

  // Function to update default creativity based on endpoint
  const updateDefaultCreativity = (endpoint) => {
    const denoising_strength = endpointDefaultDenoising[endpoint] || 0.07;
    const defaultVisualValue = backendToVisualValue(denoising_strength, 0.07); // Starting visualValue based on denoising_strength and secondary_value

    setDefaultCreativity({
      denoisingStrength: denoising_strength, // Default for denoising_strength
      secondaryValue: 0.07, // Default for secondary_value
    });

    if (!creativityEnabled) {
      setCreativity({
        visualValue: defaultVisualValue,
        denoisingStrength: denoising_strength,
        secondaryValue: 0.07,
      });

      setSelectedMetadata((prev) => ({
        ...prev,
        creativity: denoisingToCreativity(denoising_strength),
      }));
    }
  };

  // Initial Image Loading Effect - Runs only once
  useEffect(() => {
    fetchUserProfile();

    const activeSessionData = sessionStorage.getItem('activeSession');

    if (activeSessionData) {
      const session = JSON.parse(activeSessionData);
      setOriginalImageUrl(session.originalImage.url);
      setUploadedImageUrl(session.originalImage.url);
      setImageDimensions({
        width: session.originalImage.width || 0,
        height: session.originalImage.height || 0,
      });

      // Create arrays for gallery images and metadata
      const variantUrls = [];
      const variantMetadata = [];

      // Add the original image as the first entry
      if (session.originalImage?.url) {
        variantUrls.push(session.originalImage.url);
        variantMetadata.push({
          width: session.originalImage.width || 0,
          height: session.originalImage.height || 0,
          format: session.originalImage.format || 'UNKNOWN',
          scaleFactor: 1, // Original image should have scale factor of 1
          isProcessed: false, // The original image is not processed
          creativity: 0,
          composition: 0,
          hdrStrength: 0,
          filmGrainVisual: 0, // Disabled
          filmGrainStrength: 0, // Backend value
          optimizedFor: 'Auto',
        });
      }

      // Add variants to the gallery, if they exist
      if (session.variants && Array.isArray(session.variants)) {
        session.variants.forEach((variant) => {
          if (variant.url && variant.url !== session.originalImage.url) {
            variantUrls.push(variant.url);
            variantMetadata.push({
              width: variant.width || variant.finalWidth || 0,
              height: variant.height || variant.finalHeight || 0,
              format: variant.format || 'UNKNOWN',
              scaleFactor: session.settings?.scaleFactor || 1,
              isProcessed: true,
              creativity: variant.settings?.creativity || 0,
              composition: variant.settings?.resemblance || 0,
              hdrStrength: variant.settings?.hdrStrength || 0,
              filmGrainVisual: mapGrainStrengthBackendToSlider(variant.filmGrainStrength || 0), // Map backend to slider
              filmGrainStrength: variant.filmGrainStrength || 0,
              optimizedFor: variant.settings?.model || 'Auto',
            });
          }
        });
      }

      // Update the gallery images and metadata
      setGalleryImages(variantUrls);
      setImageMetadata(variantMetadata);

      // Set initial selected variant to the first item in the gallery
      if (variantUrls.length > 0) {
        setSelectedImageState(variantUrls[0]);
        setSelectedMetadata(variantMetadata[0]);
        setSelectedIndex(0);

        // Initialize Film Grain Strength states based on the selected image
        const initialFilmGrainVisual = variantMetadata[0].filmGrainVisual;
        const initialFilmGrainStrength = variantMetadata[0].filmGrainStrength;

        setFilmGrainVisual(initialFilmGrainVisual);
        setFilmGrainStrength(initialFilmGrainStrength);
        setFilmGrainStrengthEnabled(initialFilmGrainStrength > 0);
      }

      // Set the active session
      setActiveSession(session);

      // Update settings based on session
      if (session.settings) {
        setScaleFactor(session.settings.scaleFactor || 2);

        // Handle Creativity
        if (session.settings.creativity && session.settings.creativity.visualValue > 0) {
          const { visualValue, denoisingStrength, secondaryValue } = session.settings.creativity;
          setCreativity({
            visualValue: visualValue,
            denoisingStrength: denoisingStrength,
            secondaryValue: secondaryValue,
          });
          setCreativityEnabled(true);
        } else {
          setCreativity(DEFAULTS.creativity);
          setCreativityEnabled(false);
        }

        // Handle Resemblance
        if (session.settings.resemblance > 0) {
          setResemblance(session.settings.resemblance);
          setResemblanceEnabled(true);
        } else {
          setResemblance(DEFAULTS.resemblance);
          setResemblanceEnabled(false);
        }

        // Handle HDR Strength
        if (session.settings.hdrStrength > 0) {
          setHdrStrength(session.settings.hdrStrength);
          setHdrStrengthEnabled(true);
        } else {
          setHdrStrength(DEFAULTS.hdrStrength);
          setHdrStrengthEnabled(false); // Do not reset hdrStrength here
        }

        // Handle Film Grain Strength
        if (session.filmGrainStrength > 0) {
          // Updated mapping to use the new slider range (0-4)
          const sliderValue = mapGrainStrengthBackendToSlider(session.filmGrainStrength);
          setFilmGrainVisual(sliderValue);
          setFilmGrainStrength(session.filmGrainStrength);
          setFilmGrainStrengthEnabled(true);
        } else {
          setFilmGrainVisual(DEFAULTS.filmGrainVisual);
          setFilmGrainStrength(DEFAULTS.filmGrainStrength);
          setFilmGrainStrengthEnabled(false);
        }

        setSelectedModel(session.settings.model || 'Auto');

        // Update defaultCreativity based on the endpoint associated with the model
        const endpoint = selectedCard?.endpoint || 'upscale';
        updateDefaultCreativity(endpoint);
      }

      // Remove 'activeSession' from sessionStorage after loading it to avoid reloading unintentionally
      sessionStorage.removeItem('activeSession');
    } else {
      // No active session, proceed with normal flow
      const imageUrl = sessionStorage.getItem('uploadedImageUrl');
      setOriginalImageUrl(imageUrl);
      setUploadedImageUrl(imageUrl);

      if (imageUrl) {
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => {
          setImageDimensions({ width: img.width, height: img.height });
          const tokenCost = calculateTokensRequired(img.width, img.height, scaleFactor);
          setTokensRequired(tokenCost);
          console.log(`Tokens required set to: ${tokenCost}`);

          const metadata = {
            width: img.width,
            height: img.height,
            format:
              img.src.split('.').pop().split('?')[0].toUpperCase() || 'UNKNOWN',
            scaleFactor: scaleFactor,
            isProcessed: false,
            creativity: creativityEnabled
              ? denoisingToCreativity(creativity.denoisingStrength)
              : denoisingToCreativity(defaultCreativity.denoisingStrength),
            composition: resemblanceEnabled
              ? resemblance
              : DEFAULTS.resemblance,
            hdrStrength: hdrStrengthEnabled ? hdrStrength : DEFAULTS.hdrStrength,
            filmGrainVisual: filmGrainStrengthEnabled
              ? mapGrainStrengthBackendToSlider(filmGrainStrength)
              : 0,
            filmGrainStrength: filmGrainStrengthEnabled
              ? filmGrainStrength
              : DEFAULTS.filmGrainStrength,
            optimizedFor: selectedModel,
          };

          setImageMetadata([metadata]);
          setSelectedMetadata(metadata);
          setGalleryImages([imageUrl]);
          setSelectedImageState(imageUrl);
          setSelectedIndex(0);

          // Update settings state variables based on uploaded image
          setScaleFactor(metadata.scaleFactor || 2);

          // Handle Creativity
          if (metadata.creativity > 0) {
            const visualValue = backendToVisualValue(
              creativityToDenoising(metadata.creativity),
              metadata.secondaryValue
            );
            setCreativity({
              visualValue: visualValue, // 0-100
              denoisingStrength: creativityToDenoising(metadata.creativity),
              secondaryValue: 0.07,
            });
            setCreativityEnabled(true);
          } else {
            setCreativity(DEFAULTS.creativity);
            setCreativityEnabled(false);
          }

          // Handle Resemblance
          if (metadata.composition > 0) {
            setResemblance(metadata.composition);
            setResemblanceEnabled(true);
          } else {
            setResemblance(DEFAULTS.resemblance);
            setResemblanceEnabled(false);
          }

          // Handle HDR Strength
          if (metadata.hdrStrength > 0) {
            setHdrStrength(metadata.hdrStrength);
            setHdrStrengthEnabled(true);
          } else {
            setHdrStrength(DEFAULTS.hdrStrength);
            setHdrStrengthEnabled(false); // Do not reset hdrStrength here
          }

          // Handle Film Grain Strength
          if (metadata.filmGrainStrength > 0) {
            // Updated mapping to use the new slider range (0-4)
            const sliderValue = mapGrainStrengthBackendToSlider(metadata.filmGrainStrength);
            setFilmGrainVisual(sliderValue);
            setFilmGrainStrength(metadata.filmGrainStrength);
            setFilmGrainStrengthEnabled(true);
          } else {
            setFilmGrainVisual(DEFAULTS.filmGrainVisual);
            setFilmGrainStrength(DEFAULTS.filmGrainStrength);
            setFilmGrainStrengthEnabled(false);
          }

          setSelectedModel(metadata.optimizedFor || 'Auto');

          // Update defaultCreativity based on the endpoint
          const endpoint = selectedCard?.endpoint || 'upscale';
          updateDefaultCreativity(endpoint);
        };

        img.onerror = (error) => {
          console.error('Image failed to load:', error);
          message.error('Failed to load the uploaded image.');
        };
      }

      // Fetch images if no specific session is being continued
      fetchImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUserProfile, selectedCard?.endpoint]); // Added selectedCard.endpoint to dependencies

  // Separate useEffect for recalculating tokens when scaleFactor or imageDimensions change
  useEffect(() => {
    if (imageDimensions.width && imageDimensions.height) {
      const tokenCost = calculateTokensRequired(
        imageDimensions.width,
        imageDimensions.height,
        scaleFactor
      );
      setTokensRequired(tokenCost);
      console.log(`Tokens required updated to: ${tokenCost}`);
    }
  }, [scaleFactor, imageDimensions]);

  // Handle New Upscaled Image
  useEffect(() => {
    if (isUpscaled && upscaledImageUrl) {
      // Create metadata for the new image
      const newMetadata = {
        width: imageDimensions.width * scaleFactor,
        height: imageDimensions.height * scaleFactor,
        format: 'JPG',
        scaleFactor: scaleFactor,
        isProcessed: true,
        creativity: creativityEnabled
          ? denoisingToCreativity(creativity.denoisingStrength)
          : denoisingToCreativity(defaultCreativity.denoisingStrength),
        composition: resemblanceEnabled ? resemblance : DEFAULTS.resemblance,
        hdrStrength: hdrStrengthEnabled ? hdrStrength : 0, // Set to 0 if HDR is disabled
        filmGrainVisual: filmGrainVisual, // Add current visual value for Film Grain
        filmGrainStrength: filmGrainVisual, // Backend value
        optimizedFor: selectedModel,
      };
    
      console.log('New Upscaled Image Metadata:', newMetadata);
    
      // Add the new image and metadata to the gallery
      setGalleryImages((prev) => [...prev, upscaledImageUrl]);
      setImageMetadata((prev) => [...prev, newMetadata]);
    
      // Select the new image
      const newIndex = galleryImages.length;
      setSelectedImageState(upscaledImageUrl);
      setSelectedMetadata(newMetadata);
      setSelectedIndex(newIndex);
    
      // Reset the upscaled flag
      setIsUpscaled(false);
    
      // Fetch user profile to update tokens
      fetchUserProfile();
    }
    
  }, [
    isUpscaled,
    upscaledImageUrl,
    imageDimensions,
    scaleFactor,
    creativityEnabled,
    creativity,
    resemblanceEnabled,
    resemblance,
    hdrStrengthEnabled,
    hdrStrength,
    filmGrainStrengthEnabled,
    filmGrainStrength,
    selectedModel,
    defaultCreativity,
    DEFAULTS.resemblance,
    DEFAULTS.filmGrainVisual,
    fetchUserProfile,
    galleryImages.length,
  ]);

  // Function to calculate tokens required based on image dimensions and scale factor
  const calculateTokensRequired = (width, height, scaleFactor) => {
    if (width === 0 || height === 0) {
      console.error(
        'Width or Height is zero, cannot calculate tokens required.'
      );
      return MIN_TOKENS;
    }

    const scaledWidth = width * scaleFactor;
    const scaledHeight = height * scaleFactor;
    const scaledPixels = scaledWidth * scaledHeight;
    const scaledMegapixels = scaledPixels / 1_000_000;
    const tokenCost = Math.ceil(scaledMegapixels);

    console.log(
      `Calculated token cost: ${tokenCost} for ${scaledMegapixels} MP`
    );
    return Math.max(tokenCost, MIN_TOKENS);
  };

  // Function to handle toggle switches
  const handleToggle = (key, value) => {
    setIsManuallyAdjusting(true);
    console.log(`Toggle ${key}: ${value}`);

    switch (key) {
      case 'scaleFactor': {
        setScaleFactor(value);
        return;
      }

      case 'creativity': {
        setCreativityEnabled(value);
        if (value) {
          // Enable creativity: preserve existing visualValue and secondaryValue or set defaults
          const currentDenoisingStrength =
            creativity.denoisingStrength || defaultCreativity.denoisingStrength;
          const currentSecondaryValue =
            creativity.secondaryValue !== undefined
              ? creativity.secondaryValue
              : defaultCreativity.secondaryValue;

          const newVisualValue = denoisingToCreativity(currentDenoisingStrength);

          setCreativity({
            visualValue: newVisualValue,
            denoisingStrength: currentDenoisingStrength,
            secondaryValue: currentSecondaryValue,
          });

          setSelectedMetadata((prev) => ({
            ...prev,
            creativity: denoisingToCreativity(currentDenoisingStrength),
          }));
        } else {
          // Disable creativity: reset to defaults
          setCreativity({
            visualValue: denoisingToCreativity(defaultCreativity.denoisingStrength),
            denoisingStrength: defaultCreativity.denoisingStrength,
            secondaryValue: 0.07,
          });
          setSelectedMetadata((prev) => ({
            ...prev,
            creativity: denoisingToCreativity(defaultCreativity.denoisingStrength),
          }));
        }
        return;
      }

      case 'resemblance': {
        setResemblanceEnabled(value);
        if (value) {
          const currentValue = resemblance || DEFAULTS.resemblance;
          setResemblance(currentValue);
          setSelectedMetadata((prev) => ({
            ...prev,
            composition: currentValue,
          }));
        } else {
          setResemblance(DEFAULTS.resemblance);
          setSelectedMetadata((prev) => ({
            ...prev,
            composition: DEFAULTS.resemblance,
          }));
        }
        return;
      }

      case 'hdrStrength': {
        setHdrStrengthEnabled(value);
        if (value) {
          // When enabling HDR, retain existing hdrStrength or set to default
          const currentValue = hdrStrength || DEFAULTS.hdrStrength;
          setSelectedMetadata((prev) => ({
            ...prev,
            hdrStrength: currentValue,
          }));
        } else {
          // When disabling HDR, set hdr_strength in metadata to 0
          setSelectedMetadata((prev) => ({
            ...prev,
            hdrStrength: 0,
          }));
        }
        return;
      }

      case 'filmGrainStrength': {
        setFilmGrainStrengthEnabled(value);
        if (value) {
          // When enabling, always map fresh values
          const currentSliderValue = filmGrainVisual || 1; // Default to 1 if no value
          const mappedValue = mapGrainStrength(currentSliderValue);
          
          setFilmGrainVisual(currentSliderValue);
          setFilmGrainStrength(mappedValue);
          
          setSelectedMetadata((prev) => ({
            ...prev,
            filmGrainVisual: currentSliderValue,
            filmGrainStrength: mappedValue
          }));
        } else {
          // When disabling, reset both values to 0
          setFilmGrainVisual(0);
          setFilmGrainStrength(0);
          setSelectedMetadata((prev) => ({
            ...prev,
            filmGrainVisual: 0,
            filmGrainStrength: 0
          }));
        }
        return;
      }

      default: {
        console.warn(`Unknown toggle key: ${key}`);
        return;
      }
    }
  };

  // Function to handle slider changes
  const handleSettingsChange = (key, value) => {
    setIsManuallyAdjusting(true);
  
    if (key === 'filmGrainStrength') {
      // Validate the incoming value
      const normalizedValue = Math.round(Number(value));
  
      if (isNaN(normalizedValue) || normalizedValue < 0 || normalizedValue > 4) {
        console.error(`Invalid film grain value: ${value}`);
        return;
      }
  
      // Update the visual value
      setFilmGrainVisual(normalizedValue);
  
      // Update the backend value
      const mappedValue = mapGrainStrength(normalizedValue);
      setFilmGrainStrength(mappedValue);
  
      // Update metadata
      setSelectedMetadata((prev) => ({
        ...prev,
        filmGrainVisual: normalizedValue,
        filmGrainStrength: mappedValue
      }));
  
      console.log('Film grain updated:', {
        normalizedValue,
        mappedValue,
        enabled: filmGrainStrengthEnabled
      });
    } else {
      switch (key) {
        case 'creativity': {
          // Use the updated conversion functions
          const backendValues = visualToBackendValues(value);
          console.log(
            `Setting creativity: visualValue=${value}, denoisingStrength=${backendValues.denoisingStrength}, secondaryValue=${backendValues.secondaryValue}`
          );
  
          setCreativity({
            visualValue: value,
            ...backendValues,
          });
  
          setSelectedMetadata((prev) => ({
            ...prev,
            creativity: denoisingToCreativity(backendValues.denoisingStrength),
          }));
          break;
        }
        case 'resemblance':
          setResemblance(value);
          setSelectedMetadata((prev) => ({
            ...prev,
            composition: value,
          }));
          break;
        case 'hdrStrength':
          setHdrStrength(value);
          if (hdrStrengthEnabled) {
            setSelectedMetadata((prev) => ({
              ...prev,
              hdrStrength: value,
            }));
          }
          break;
        case 'scaleFactor':
          setScaleFactor(value);
          break;
        default:
          console.warn(`Unknown settings key: ${key}`);
          break;
      }
    }
  };
  

  // Function to handle the upscale process
// Function to handle the upscale process
const handleUpscale = async () => {
  setIsManuallyAdjusting(false);

  const token = Cookies.get('token');
  if (!token) {
    message.error('Please log in first.');
    navigate('/login');
    return;
  }

  if (tokensAvailable <= 1) {
    message.error('Not enough tokens to proceed. Please add more tokens.');
    return;
  }

  if (tokensAvailable < tokensRequired) {
    message.error('Not enough tokens. Please purchase more.');
    return;
  }

  setIsUpscaling(true);
  try {
    // Validate film grain settings before making the API call
    let validatedFilmGrainStrength = 0;
    let validatedFilmGrainVisual = 0;

    if (filmGrainStrengthEnabled) {
      // Validate visual value first
      if (typeof filmGrainVisual !== 'number' || filmGrainVisual < 0 || filmGrainVisual > 4) {
        message.error('Invalid film grain visual value. Please adjust the slider.');
        return;
      }

      // Map visual value to strength
      validatedFilmGrainStrength = mapGrainStrength(filmGrainVisual);

      // Allow 0 or ensure the mapped strength is within 4-7
      if (validatedFilmGrainStrength !== 0 && (validatedFilmGrainStrength < 4 || validatedFilmGrainStrength > 7)) {
        message.error('Invalid film grain strength. Please adjust the slider.');
        return;
      }

      validatedFilmGrainVisual = Math.round(filmGrainVisual);
    }

    // Calculate backend values based on settings
    const currentEndpoint = selectedCard?.endpoint || 'upscale';
    const denoisingStrength = creativityEnabled
      ? creativity.denoisingStrength
      : defaultCreativity.denoisingStrength;
    const secondaryValue = creativityEnabled
      ? creativity.secondaryValue
      : 0.07; // Default secondary_value when creativity is disabled

    const resemblanceBackendValue = resemblanceEnabled
      ? mapCompositionToResemblance(resemblance)
      : DEFAULTS.resemblance;

    const endpointURL = selectedCard?.endpoint
      ? `https://www.ek0go8g.vudoo.ai/api/${selectedCard.endpoint}`
      : 'https://www.ek0go8g.vudoo.ai/api/upscale';

    const payload = {
      input_image: originalImageUrl,
      scale_factor: scaleFactor,
      denoising_strength: denoisingStrength, // 0 or 0.07-1.00
      secondary_value: secondaryValue,       // 0 or 0.07-0.20
      resemblance: resemblanceBackendValue,
      hdr_strength: hdrStrengthEnabled ? hdrStrength : 0, // Set to 0 when HDR is disabled
      film_grain_strength: validatedFilmGrainStrength,
      film_grain_visual: validatedFilmGrainVisual,
      width: imageDimensions.width,
      height: imageDimensions.height,
      model: modelOptions[selectedModel],
      tokensSpent: tokensRequired,
    };

    console.log('Payload sent to upscale API:', payload);

    const response = await fetch(endpointURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) throw new Error('Failed to upscale image');

    const result = await response.json();

    if (!result.upscaledImage) {
      throw new Error('Upscaled image not returned from API');
    }

    setUpscaledImageUrl(result.upscaledImage);
    setIsUpscaled(true);
    message.success('Image upscaled successfully!');

    await deductCredits(tokensRequired);
    console.log('Tokens deducted:', tokensRequired);
  } catch (error) {
    console.error('Upscaling failed:', error);
    message.error(`Upscaling failed: ${error.message}`);
  } finally {
    setIsUpscaling(false);
  }
};


  // Function to handle image downloads
  const handleDownload = (format) => {
    if (!selectedImageState) {
      console.error('No image selected for download.');
      message.error(
        'No image selected for download. Please select a variant.'
      );
      return;
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = selectedImageState;

    img.onload = () => {
      const { width, height } = selectedMetadata;
      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          if (blob) {
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `vudoo_${width}x${height}.${format}`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
            message.success(`Downloading ${format.toUpperCase()} file...`);
            console.log(
              `Downloading ${format.toUpperCase()} file: vudoo_${width}x${height}.${format}`
            );
          } else {
            message.error('Failed to create an image for download.');
            console.error('Canvas toBlob returned null.');
          }
        },
        `image/${format}`
      );
    };

    img.onerror = () => {
      console.error('Failed to load image for download.');
      message.error('Download failed. Please try again.');
    };
  };

  // Function to handle zoom changes
  const handleZoomChange = (direction) => {
    setZoomLevel((prevZoom) => {
      const newZoom =
        direction === 'in'
          ? Math.min(prevZoom + 10, 300)
          : Math.max(prevZoom - 10, 50);
      console.log(`Zoom level changed to: ${newZoom}%`);
      return newZoom;
    });
  };

  // Function to fetch user upscaled images
  const fetchImages = async (pageToken = null) => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
      return;
    }

    setIsLoading(true);

    try {
      const url = new URL(
        'https://www.ek0go8g.vudoo.ai/api/getUserUpscaledImages'
      );
      const params = {
        limit: 20,
      };
      if (pageToken) {
        params.lastVisible = pageToken;
      }
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch images');
      }

      const data = await response.json();

      if (!data.sessions || !Array.isArray(data.sessions)) {
        console.error('API response does not contain sessions array:', data);
        throw new Error('Invalid data format received from API');
      }

      // Extract images and metadata from sessions
      const allImages = [];
      const allMetadata = [];

      data.sessions.forEach((session) => {
        // Handle original image
        if (session.originalImage) {
          allImages.push(session.originalImage.url);
          allMetadata.push({
            width: session.originalImage.width || 0,
            height: session.originalImage.height || 0,
            format: session.originalImage.format || 'UNKNOWN',
            scaleFactor: 1,
            isProcessed: false,
            creativity: 0,
            composition: 0,
            hdrStrength: 0,
            filmGrainVisual: 0, // Disabled
            filmGrainStrength: 0, // Backend value
            optimizedFor: 'Auto',
          });
        }

        // Handle variants
        if (session.variants && Array.isArray(session.variants)) {
          session.variants.forEach((variant) => {
            if (variant.url && variant.url !== session.originalImage.url) {
              allImages.push(variant.url);

              const metadata = {
                width: variant.width || variant.finalWidth || 0,
                height: variant.height || variant.finalHeight || 0,
                format: variant.format || 'UNKNOWN',
                scaleFactor: session.settings?.scaleFactor || 1,
                isProcessed: true,
                creativity: variant.settings?.creativity || 0,
                composition: variant.settings?.resemblance || 0,
                hdrStrength: variant.settings?.hdrStrength || 0,
                filmGrainVisual: mapGrainStrengthBackendToSlider(variant.filmGrainStrength || 0),
                filmGrainStrength: variant.filmGrainStrength || 0,
                optimizedFor: variant.settings?.model || 'Auto',
              };

              allMetadata.push(metadata);
            }
          });
        }
      });

      // Update state with new images and metadata
      setGalleryImages((prevImages) => {
        const uniqueImages = [...new Set([...prevImages, ...allImages])];
        console.log(
          `Gallery images updated. Total images: ${uniqueImages.length}`
        );
        return uniqueImages;
      });

      setImageMetadata((prevMetadata) => {
        const newMetadata = [...prevMetadata, ...allMetadata];
        console.log(
          `Image metadata updated. Total metadata entries: ${newMetadata.length}`
        );
        return newMetadata;
      });
      setNextPageToken(data.nextPageToken || null);

      console.log(`Fetched ${allImages.length} new images.`);
    } catch (error) {
      console.error('Error fetching images:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle loading more images
  const handleLoadMore = () => {
    if (nextPageToken) {
      fetchImages(nextPageToken);
    }
  };

  // Updated handleThumbnailClick Function
  // Function to handle thumbnail clicks
  const handleThumbnailClick = (imageUrl, metadata, index) => {
    console.log('Thumbnail clicked:', imageUrl, metadata);
    const img = new Image();
    img.src = imageUrl;

    // Preload the new image before updating the state
    img.onload = () => {
      setSelectedImageState(imageUrl);
      setSelectedIndex(index);

      // Update scale factor
      setScaleFactor(metadata.scaleFactor > 0 ? metadata.scaleFactor : 2);

      // Update creativity
      const visualValue = metadata.creativity > 0 ? metadata.creativity : 0;

      setCreativity({
        visualValue: visualValue, // 0-100
        denoisingStrength: creativityToDenoising(visualValue),
        secondaryValue: metadata.secondaryValue || 0.07,
      });
      setCreativityEnabled(visualValue > 0);

      // Update resemblance
      const currentResemblance = metadata.composition > 0 ? metadata.composition : DEFAULTS.resemblance;
      setResemblance(currentResemblance);
      setResemblanceEnabled(metadata.composition > 0);

      // Update HDR Strength
      const currentHdrStrength = metadata.hdrStrength > 0 ? metadata.hdrStrength : DEFAULTS.hdrStrength;
      setHdrStrength(currentHdrStrength);
      setHdrStrengthEnabled(metadata.hdrStrength > 0);

      // Update Film Grain Strength and Visual
      const currentFilmGrainVisual = metadata.filmGrainVisual || 0;
      const currentFilmGrainStrength = metadata.filmGrainStrength || 0;

      setFilmGrainVisual(currentFilmGrainVisual);
      setFilmGrainStrength(currentFilmGrainStrength);
      setFilmGrainStrengthEnabled(currentFilmGrainStrength > 0);

      setSelectedModel(metadata.optimizedFor || 'Auto');

      // Update selected metadata
      setSelectedMetadata({
        ...metadata,
        creativity: visualValue, // Use the same value as the slider
        composition: currentResemblance,
        hdrStrength: currentHdrStrength,
        filmGrainVisual: currentFilmGrainVisual,
        filmGrainStrength: currentFilmGrainStrength,
      });

      // Reset manual adjustment flag when switching variants
      setIsManuallyAdjusting(false);

      console.log('Updated creativity values:', {
        visualValue,
        denoisingStrength: creativityToDenoising(visualValue),
        secondaryValue: metadata.secondaryValue,
        metadataCreativity: metadata.creativity
      });
    };

    img.onerror = () => {
      console.error('Failed to load image for display.');
      message.error('Failed to load the selected image.');
    };
  };

  // Function to handle model changes
  const handleModelChange = (value) => {
    // Set Manual Adjustment Flag
    setIsManuallyAdjusting(true);
    setSelectedModel(value);
    console.log('Selected Model changed to:', value);

    // Update defaultCreativity based on the new model's endpoint
    const endpoint = selectedCard?.endpoint || 'upscale';
    updateDefaultCreativity(endpoint);
  };

  // Function to toggle dragging
  const toggleDragging = () => {
    setIsDraggingEnabled((prev) => !prev);
    console.log(`Dragging enabled: ${!isDraggingEnabled}`);
  };

  // Function to handle modal closure
  const handleModalClose = (selectedVariant) => {
    setIsModalOpen(false);
    if (selectedVariant) {
      const index = galleryImages.findIndex(
        (url) => url === selectedVariant.url
      );
      if (index !== -1) {
        handleThumbnailClick(
          galleryImages[index],
          imageMetadata[index],
          index
        );

        console.log('Modal closed. Selected variant settings updated.');
      }
    }
  };

  // Function to handle continuing with the project
  const handleContinueWithProject = () => {
    navigate('/some-path'); // Replace with actual path
    handleModalClose();
  };

  // Props for DownloadSection
  const downloadSectionProps = {
    handleDownload,
    outputResolution: `${selectedMetadata.width} x ${selectedMetadata.height}`,
    isCollapsed: !showDownloadSection,
    toggleCollapse: () => setShowDownloadSection(!showDownloadSection),
  };

  // Props for header
  const headerProps = {
    newAction: 'New Upscale',
    newActionPath: '/upload?type=upscaler',
  };

  // Sidebar content including Gallery and SettingsPanel
  const sidebarContent = (
    <>
      <Gallery
        images={galleryImages}
        imageMetadata={imageMetadata}
        onThumbnailClick={handleThumbnailClick}
        isCollapsed={!showInputVariants}
        toggleCollapse={() => setShowInputVariants(!showInputVariants)}
        isUpscalePage={true}
        isProcessing={isUpscaling}  // Pass the processing state

      />
      <SettingsPanel
        settings={{
          scaleFactor,
          outputResolution: `${selectedMetadata.width} x ${selectedMetadata.height}`,
          tokensAvailable,
          tokensRequired,
          optimizedFor: selectedModel,
          onModelChange: handleModelChange,
          defaultCreativity: denoisingToCreativity(defaultCreativity.denoisingStrength),
          options: [
            {
              label: 'Creativity',
              key: 'creativity',
              enabled: creativityEnabled,
              min: 0,           // Updated minimum value to 0
              max: 100,         // Updated maximum value to 100
              step: 1,
              value: creativity.visualValue, // Use visualValue for the slider
              labelLeft: 'Low',
              labelRight: 'High',
              tooltip: `Denoising Strength: ${
                typeof creativity.denoisingStrength === 'number'
                  ? creativity.denoisingStrength.toFixed(2)
                  : '0.00'
              }, Secondary Value: ${
                typeof creativity.secondaryValue === 'number'
                  ? creativity.secondaryValue.toFixed(2)
                  : '0.00'
              }`,
              isSlider: true, // Custom flag to indicate this is a slider
              className: "w-full", // Added for consistent alignment
            },
            {
              label: 'Composition',
              key: 'resemblance',
              enabled: resemblanceEnabled,
              min: 0,
              max: 10,
              step: 1,
              value: resemblance,
              labelLeft: 'Strict',
              labelRight: 'Hallucinate',
            },
            {
              label: 'HDR',
              key: 'hdrStrength',
              enabled: hdrStrengthEnabled,
              min: 0,
              max: 10,
              step: 1,
              value: hdrStrength,
              labelLeft: '0',
              labelRight: '10',
            },
            {
              label: 'Film Grain',
              key: 'filmGrainStrength',
              enabled: filmGrainStrengthEnabled,
              min: 0,    // Updated min to 0 for disabled state
              max: 4,    // Updated max to 4 as per new slider range
              step: 1,
              value: filmGrainVisual, // Slider value (0-4)
              labelLeft: 'Low',
              labelRight: 'High',
              tooltip: 'Adjust the amount of film grain applied to the image',
              isSlider: true, // Custom flag to indicate this is a slider
              className: "w-full", // For consistent alignment
            },
          ],
          actionButton: (
            <CustomButton
              onClick={handleUpscale}
              disabled={isUpscaling}
            >
              {isUpscaling ? 'Upscaling...' : 'Upscale'}
            </CustomButton>
          ),
        }}
        onToggle={handleToggle}
        onChange={handleSettingsChange}
        isUpscalePage={true}
        isDefault={isDefault}
        currentEndpoint={selectedCard?.endpoint || 'upscale'}
      />

      {selectedMetadata.isProcessed && (
        <DownloadSection {...downloadSectionProps} />
      )}

      {nextPageToken && (
        <div className="flex justify-center mt-4">
          <CustomButton onClick={handleLoadMore} loading={isLoading}>
            Load More
          </CustomButton>
        </div>
      )}
    </>
  );

  // Props for zoom controls
  const zoomControlsProps = {
    zoomLevel,
    onZoomIn: () => setZoomLevel((prev) => Math.min(prev + 10, 300)),
    onZoomOut: () => setZoomLevel((prev) => Math.max(prev - 10, 50)),
    onResetZoom: () => setZoomLevel(100),
    isDraggingEnabled,
    onToggleDragging: toggleDragging,
  };

  return (
    <div>
      <ImageModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        session={activeSession}
        onContinue={handleContinueWithProject}
      />
      <SharedLayout
        headerProps={headerProps}
        sidebarContent={sidebarContent}
        zoomControlsProps={zoomControlsProps}
      >
        <ImageDisplay
          key="image-display"
          uploadedImageUrl={originalImageUrl}
          selectedImageUrl={selectedImageState}
          isProcessing={isUpscaling}
          isProcessed={selectedMetadata.isProcessed}
          zoomLevel={zoomLevel}
          onResetZoom={zoomControlsProps.onResetZoom}
          isDraggingEnabled={isDraggingEnabled}
          upscaledImageUrl={upscaledImageUrl}
          processType="upscaling"
          filmGrainStrength={filmGrainStrengthEnabled ? filmGrainStrength : 0} // Backend value
        />
      </SharedLayout>
    </div>
  );
};

export default UpscalerPage;
