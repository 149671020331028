// src/components/shared/ImageModal.js

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DownloadIcon } from './Assets/DownloadIcon.svg';

// Reusable Badge Component with consistent design and flexible placement
const Badge = ({ label, className }) => (
  <div
    className={`badge truncate px-2 py-0.5 text-white text-xs font-semibold uppercase leading-[14px] tracking-wide text-center ${className}`}
  >
    {label}
  </div>
);

Badge.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Badge.defaultProps = {
  className: '',
};

const ImageModal = ({ isOpen, onClose, session, onContinue }) => {
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  const imageRef = useRef(null);
  const containerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    if (isOpen && session) {
      window.addEventListener('keydown', handleEsc);
      setTimeout(() => setIsVisible(true), 10);

      if (session.variants?.length > 0) {
        setSelectedVariant(session.variants[session.variants.length - 1]);
      }
    }

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen, session]);

  useEffect(() => {
    const updateImageSize = () => {
      if (imageRef.current && containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const containerAspectRatio = containerRect.width / containerRect.height;
        const imageAspectRatio = imageRef.current.naturalWidth / imageRef.current.naturalHeight;

        let width, height;

        if (imageAspectRatio > containerAspectRatio) {
          width = containerRect.width - 32;
          height = width / imageAspectRatio;
        } else {
          height = containerRect.height - 32;
          width = height * imageAspectRatio;
        }

        setImageSize({ width, height });
      }
    };

    updateImageSize();
    window.addEventListener('resize', updateImageSize);

    return () => {
      window.removeEventListener('resize', updateImageSize);
    };
  }, [selectedVariant]);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      if (selectedVariant && onClose) {
        // Pass the selected variant back to the parent component on close
        onClose(selectedVariant);
      }
    }, 300);
  };

  const handleContinueWithProject = () => {
    if (session) {
      const toolUsed = selectedVariant?.tool ? selectedVariant.tool.toLowerCase() : '';
      const storageKey =
        toolUsed === 'image restoration' ? 'activeRestorationSession' : 'activeSession';
      sessionStorage.setItem(storageKey, JSON.stringify(session));
      navigate(toolUsed === 'image restoration' ? '/restoration' : '/upscaler');
    }
    handleClose();
  };

  const handleDownload = async (format) => {
    if (!selectedVariant?.url) return;

    try {
      const response = await fetch(selectedVariant.url);
      if (!response.ok) throw new Error('Failed to download image');

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      const filenameBase = session.sessionName
        ? session.sessionName.replace(/[^a-zA-Z0-9-_]/g, '_')
        : 'vudoo_session';
      link.setAttribute('download', `vudoo_${filenameBase}.${format.toLowerCase()}`);

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      alert('Failed to download the image.');
    }
  };

  const renderMetadataInfo = () => {
    if (!selectedVariant || !session) {
      return null;
    }

    const isOriginal = selectedVariant.url === session.originalImage.url;

    return (
      <div className="flex flex-col justify-start gap-1 w-full text-13" style={{ minHeight: '150px' }}>
        {selectedVariant.width && selectedVariant.height && (
          <div className="flex justify-between items-center w-full">
            <span className="opacity-50 text-white">Resolution:</span>
            <span className="ml-1 text-white">
              {selectedVariant.width} x {selectedVariant.height}
            </span>
          </div>
        )}

        {!isOriginal && selectedVariant.settings && (
          <>
            {selectedVariant.settings.model && (
              <div className="flex justify-between items-center w-full">
                <span className="opacity-50 text-white">Model:</span>
                <span className="ml-1 text-white">{selectedVariant.settings.model}</span>
              </div>
            )}
            {selectedVariant.settings.scaleFactor !== undefined && (
              <div className="flex justify-between items-center w-full">
                <span className="opacity-50 text-white">Scale Factor:</span>
                <span className="ml-1 text-white">{selectedVariant.settings.scaleFactor}x</span>
              </div>
            )}
            {selectedVariant.settings.creativity !== undefined && (
              <div className="flex justify-between items-center w-full">
                <span className="opacity-50 text-white">Creativity:</span>
                <span className="ml-1 text-white">{selectedVariant.settings.creativity}</span>
              </div>
            )}
            {selectedVariant.settings.resemblance !== undefined && (
              <div className="flex justify-between items-center w-full">
                <span className="opacity-50 text-white">Composition:</span>
                {/* Format resemblance to remove decimals */}
                <span className="ml-1 text-white">
                  {Math.round(selectedVariant.settings.resemblance)}
                </span>
              </div>
            )}
            {selectedVariant.settings.hdrStrength !== undefined && (
              <div className="flex justify-between items-center w-full">
                <span className="opacity-50 text-white">HDR Strength:</span>
                <span className="ml-1 text-white">
                  {selectedVariant.settings.hdrStrength !== 0
                    ? selectedVariant.settings.hdrStrength
                    : 'OFF'}
                </span>
              </div>
            )}

            {/* New Section for Film Grain Strength */}
            {selectedVariant?.filmGrainStrength !== undefined && (
  <div className="flex justify-between items-center w-full">
    <span className="opacity-50 text-white">Film Grain:</span>
    <span className="ml-1 text-white">
      {selectedVariant.filmGrainStrength > 0
        ? selectedVariant.filmGrainStrength
        : 'OFF'}
    </span>
  </div>
)}



          </>
        )}
      </div>
    );
  };

  if (!isOpen || !session) return null;

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center bg-[#151515] transition-all duration-300 ease-in-out ${
        isVisible ? 'opacity-100 visible' : 'opacity-0 invisible'
      }`}
    >
      {/* Close button */}
      <button
        className="absolute top-4 right-4 z-50 text-white/70 hover:text-white transition-colors duration-200"
        onClick={handleClose}
        aria-label="Close Modal"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 6L6 18M6 6l12 12"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>

      {/* Main content container */}
      <div
        className="relative w-full h-full p-4 flex flex-col lg:flex-row gap-8 items-center"
        ref={containerRef}
      >
        {/* Image display area */}
        <div className="flex items-center justify-center flex-grow relative">
          {selectedVariant && (
            <img
              ref={imageRef}
              src={selectedVariant.url}
              alt="Selected Variant"
              className="object-contain"
              style={{
                width: `${imageSize.width}px`,
                height: `${imageSize.height}px`,
                maxWidth: '100%',
                maxHeight: '100%',
              }}
            />
          )}
        </div>

        {/* Sidebar with metadata and controls */}
        <div className="flex-shrink-0 w-full lg:w-[320px] flex flex-col gap-4">
          {/* Session information card */}
          <div className="w-full bg-[#212121] rounded-lg border border-white/10 p-3 flex flex-col gap-3">
            {/* Session header */}
            <div className="flex justify-between items-center mb-4">
              <div className="opacity-50 text-white text-[13px] leading-snug mr-2">
                Session {session.sessionName || session.sessionId}
              </div>
              <div className="w-1.5 h-1.5 bg-white rounded-full shrink-0" />
            </div>

            {/* Original image and metadata */}
            <div className="flex gap-3">
              <div className="w-[93px] h-[120px] relative">
                <img
                  src={session.originalImage.url}
                  alt="Original"
                  className="w-full h-full object-cover rounded border border-white/20"
                />
                {/* Position the Badge at top-center to intersect with the image */}
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/4">
                  <Badge label="ORIGINAL" />
                </div>
              </div>
              <div className="flex flex-col grow justify-between items-start">
                {renderMetadataInfo()}
              </div>
            </div>

            {/* Divider */}
            <div className="mx-4 my-3 h-px bg-white/10" />

            {/* Variants grid with limited height and scroll */}
            <div
              className="p-3 grid grid-cols-3 gap-x-2 gap-y-4 overflow-y-auto"
              style={{ maxHeight: '180px' }}
            >
              {session.variants.map((variant, index) => (
                <div
                  key={index}
                  className={`relative cursor-pointer ${
                    selectedVariant === variant ? 'border-[2.5px] border-[#d5d5d5] rounded-md' : ''
                  }`}
                  onClick={() => setSelectedVariant(variant)}
                >
                  <img
                    src={variant.url}
                    alt={`Variant ${index + 1}`}
                    className="w-full h-full object-cover rounded border border-white/20"
                  />
                  {/* Position the Badge centrally at the top to intersect with the image */}
                  <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/4">
                    <Badge label={index === 0 ? 'ORIGINAL' : `VARIANT ${index}`} />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Download and continue buttons */}
          <div className="w-full bg-[#212121] rounded-lg border border-white/10 p-3">
            <div className="flex gap-2 mb-2">
              <button
                onClick={() => handleDownload('PNG')}
                className="flex-grow w-20 h-10 bg-[#d5d5d5] rounded-lg justify-center items-center flex cursor-pointer hover:opacity-80"
              >
                <DownloadIcon className="w-4 h-4 mr-2 text-[#151515]" />
                <div className="text-[#151515] text-sm font-medium leading-snug">
                  PNG
                </div>
              </button>
              <button
                onClick={() => handleDownload('JPG')}
                className="flex-grow w-20 h-10 bg-[#d5d5d5] rounded-[8px] justify-center items-center flex cursor-pointer hover:opacity-80"
              >
                <DownloadIcon className="w-4 h-4 mr-2 text-[#151515]" />
                <div className="text-[#151515] text-sm font-medium leading-snug">
                  JPG
                </div>
              </button>
            </div>
            <button
              onClick={handleContinueWithProject}
              className="w-full h-10 bg-[#d5d5d5] rounded-lg justify-center items-center flex cursor-pointer hover:opacity-80 transition-colors duration-200"
            >
              <div className="text-[#151515] rounded-lg text-sm font-medium leading-snug uppercase">
                Continue project
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// PropTypes for type checking
ImageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  session: PropTypes.shape({
    sessionId: PropTypes.string.isRequired,
    sessionName: PropTypes.string,
    originalImage: PropTypes.shape({
      url: PropTypes.string.isRequired,
      format: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
      finalWidth: PropTypes.number,
      finalHeight: PropTypes.number,
    }).isRequired,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        format: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        finalWidth: PropTypes.number,
        finalHeight: PropTypes.number,
        settings: PropTypes.shape({
          model: PropTypes.string,
          scaleFactor: PropTypes.number,
          creativity: PropTypes.number,
          resemblance: PropTypes.number,
          hdrStrength: PropTypes.number,
          filmGrainStrength: PropTypes.number, // Updated field
        }),
        tool: PropTypes.string,
      })
    ).isRequired,
    uploadDate: PropTypes.string.isRequired,
    toolUsed: PropTypes.string,
  }),
  onContinue: PropTypes.func.isRequired,
};

ImageModal.defaultProps = {
  session: null,
};

export default ImageModal;
