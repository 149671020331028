import React from 'react';
import PropTypes from 'prop-types';

const UpscalerTooltip = ({ text, style }) => {
  // Helper function to safely parse the text into sections
  const parseTooltipText = (text) => {
    if (!text) return { main: '' };

    // Find the exclamation mark for the title
    const titleEndIndex = text.indexOf('!');
    if (titleEndIndex === -1) return { main: text };

    const title = text.slice(0, titleEndIndex + 1);
    const remainingText = text.slice(titleEndIndex + 1).trim();

    // Find the resolution part (text in parentheses)
    const resolutionMatch = remainingText.match(/\(([^)]+)\)/);
    if (!resolutionMatch) return { title, main: remainingText };

    const beforeResolution = remainingText.slice(0, resolutionMatch.index).trim();
    const resolution = resolutionMatch[0];
    const description = remainingText.slice(resolutionMatch.index + resolutionMatch[0].length).trim();

    return {
      title,
      details: beforeResolution,
      resolution,
      description
    };
  };

  const parts = parseTooltipText(text);

  return (
    <div
      className="w-[300px] px-4 pt-3 pb-5 bg-sidebarBackground rounded-lg border border-white/15 flex flex-col justify-center items-start font-sans text-white text-sm leading-tight"
      style={style}
      role="tooltip"
      aria-label={text}
    >
      <div className="self-stretch space-y-1">
        {parts.title ? (
          <>
            <span className="font-semibold">{parts.title}</span>
            {parts.details && <span> {parts.details}</span>}
            {parts.resolution && <span className="text-gray-400"> {parts.resolution}</span>}
            {parts.description && <span> {parts.description}</span>}
          </>
        ) : (
          <span>{text}</span>
        )}
      </div>
    </div>
  );
};

UpscalerTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  style: PropTypes.object,
};

UpscalerTooltip.defaultProps = {
  style: {},
};

export default UpscalerTooltip;