// src/data/learnItems.js
import article1Thumbnail from "./Assets/learn-section-placeholder-01.jpg";
import video1Thumbnail from "./Assets/learn-section-placeholder-02.jpg";
import article2Thumbnail from "./Assets/learn-section-placeholder-03.jpg";

export const learnItems = [
  {
    id: 1,
    title: "Learn about Image Restoration",
    type: "Article",
    thumbnailUrl: article1Thumbnail, // Use imported variable
    url: "/learn/image-restoration",
  },
  {
    id: 2,
    title: "Upscaling Basics",
    type: "Video",
    thumbnailUrl: video1Thumbnail, // Use imported variable
    url: "/learn/upscaling-basics",
  },
  {
    id: 3,
    title: "Advanced Techniques",
    type: "Article",
    thumbnailUrl: article2Thumbnail, // Use imported variable
    url: "/learn/advanced-techniques",
  },
  {
    id: 4,
    title: "Color Grading Essentials",
    type: "Video",
    thumbnailUrl: video1Thumbnail, // Reuse video1Thumbnail
    url: "/learn/color-grading",
  },
  {
    id: 5,
    title: "Introduction to AI in Photography",
    type: "Article",
    thumbnailUrl: article1Thumbnail, // Reuse article1Thumbnail
    url: "/learn/ai-photography",
  },
  {
    id: 6,
    title: "Mastering Composition",
    type: "Video",
    thumbnailUrl: video1Thumbnail, // Reuse video1Thumbnail
    url: "/learn/composition",
  },
  {
    id: 7,
    title: "Understanding Exposure",
    type: "Article",
    thumbnailUrl: article2Thumbnail, // Reuse article2Thumbnail
    url: "/learn/exposure",
  },
  {
    id: 8,
    title: "Noise Reduction Techniques",
    type: "Video",
    thumbnailUrl: video1Thumbnail, // Reuse video1Thumbnail
    url: "/learn/noise-reduction",
  },
  {
    id: 9,
    title: "Portrait Retouching",
    type: "Article",
    thumbnailUrl: article1Thumbnail, // Reuse article1Thumbnail
    url: "/learn/portrait-retouching",
  },
  {
    id: 10,
    title: "Landscape Photography Tips",
    type: "Video",
    thumbnailUrl: video1Thumbnail, // Reuse video1Thumbnail
    url: "/learn/landscape-tips",
  },
  {
    id: 11,
    title: "HDR Photography Explained",
    type: "Article",
    thumbnailUrl: article2Thumbnail, // Reuse article2Thumbnail
    url: "/learn/hdr-photography",
  },
  {
    id: 12,
    title: "Lighting for Beginners",
    type: "Video",
    thumbnailUrl: video1Thumbnail, // Reuse video1Thumbnail
    url: "/learn/lighting-basics",
  },
  {
    id: 13,
    title: "Editing Workflow Optimization",
    type: "Article",
    thumbnailUrl: article1Thumbnail, // Reuse article1Thumbnail
    url: "/learn/editing-workflow",
  },
  {
    id: 14,
    title: "Black and White Photography",
    type: "Video",
    thumbnailUrl: video1Thumbnail, // Reuse video1Thumbnail
    url: "/learn/black-white",
  },
  {
    id: 15,
    title: "Using Filters in Photography",
    type: "Article",
    thumbnailUrl: article2Thumbnail, // Reuse article2Thumbnail
    url: "/learn/filters",
  },
  {
    id: 16,
    title: "Street Photography Techniques",
    type: "Video",
    thumbnailUrl: video1Thumbnail, // Reuse video1Thumbnail
    url: "/learn/street-photography",
  },
];
